<template>
    <template v-if="this.$route.name === 'Articles'">
        <section class="container-xxl">
            <div class="sect-base">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4" v-if="!loading">
<!--                    <div class="col mb-md-5" v-for="(article, i) in articles.reverse()" :key="`article-${i}`">-->
                    <div class="col mb-md-5" v-for="(article, i) in articles" :key="`article-${i}`">
                        <card-preview class="mb-5" :post="article"/>
                    </div>
                </div>
            </div>
        </section>
    </template>

    <router-view v-else/>
</template>
<script>
    import {mapActions, mapState} from "vuex";

    import CardPreview from "@/components/AllCards/CardPreview";

    export default {
        name: 'NewsPage',
        data() {
            return {
                loading: false,
            }
        },
        components: {
            CardPreview
        },
        computed: {
            ...mapState({
                articles: state => state.articles.all
            }),
        },
        methods: {
            ...mapActions({
                getArticles: 'articles/getArticles',
            }),
        },
        async mounted() {
            this.loading = true;
            await this.getArticles();
            this.loading = false;
        },
    }
</script>